import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Ma2 from '../images/ma-2.png';
import Scen3 from '../images/scen-3.svg';
import Dm1 from '../images/dm-1.svg';
import Ad3 from '../images/a1d1-3.svg';

export default () => {
  return (
    <div className="content-list-wrapper campaign">
      <section className="image-text-section white">
        <Container>
          <ImageText image={Ma2} imgWidth={447} imgHeight={302} title="精准的活动人群定位和分层" imageSize={11}>
            <ImageTextDescription
              label="精准客户画像"
              content="通过属性、来源、事件、活跃度等指标形成精准用户画像"
            />
            <ImageTextDescription
              label="智能化标签体系"
              content="体系化的标签管理，对用户进行多维度的聚类分层"
            />
            <ImageTextDescription
              label="全渠道客户整合"
              content="对分散在多渠道的客户进行整合，营销活动精准触达"
            />
            <ImageTextDescription
              label="AI智能推荐系统"
              content="为多场景营销活动提供精准的商品推荐和人群匹配"
            />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section blue">
        <Container>
          <ImageText image={Scen3} imgWidth={423} imgHeight={340} title="自动化营销活动" position="right" imageSize={11}>
            <ImageTextDescription label="营销活动自动化运行，减少重复性工作，降低营销成本，提高活动执行效率" />
            <ImageTextDescription label="全新自动流3.0引擎，支持所有营销场景的自动化设置，提高决策准确性" />
            <ImageTextDescription label="可视化营销活动编辑器，并提供丰富的营销模板，复杂营销活动轻松运营" />
            <ImageTextDescription label="支持大规模的营销活动A/B测试，选择最优营销活动方案，确保活动ROI率" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section white">
        <Container>
          <ImageText image={Dm1} imgWidth={413} imgHeight={347} title="智能化营销活动" imageSize={11}>
            <ImageTextDescription label="AI智能圈群和聚类分析，有效提升营销人群精准度和活动效果" />
            <ImageTextDescription label="NBA（Next Best Action）智能营销决策引擎，有效提升营销RFM" />
            <ImageTextDescription label="智能推荐系统，为多场景营销活动提供精准的商品推荐和人群匹配" />
            <ImageTextDescription label="智能化广告投放管理，实时人群决策引擎提升营销活动广告投放ROI" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section blue">
        <Container>
          <ImageText image={Ad3} imgWidth={426} imgHeight={353} title="企业级的营销活动产品" position="right" imageSize={11}>
            <ImageTextDescription label="完善的营销活动统计、分析和策略迭代" />
            <ImageTextDescription label="丰富的全行业营销活动项目实施经验" />
            <ImageTextDescription label="提供营销活动标签解决方案和顾问服务" />
            <ImageTextDescription label="专业投放团队提供广告咨询和运营服务" />
          </ImageText>
        </Container>
      </section>
    </div>
  );
};
