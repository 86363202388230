import React from 'react';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CircleTopBox from '../components/CircleTopBox';
import CommonPageBanner from '../components/CommonPageBanner';
import MarketingMgtContentList from '../components/MarketingMgtContentList';
import HomeLogos from '../components/HomeLogos';
import Container from '../components/Container';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';

const TOP_BOX_DATA = [
  {
    iconId: 'iconmarketing-activity-1',
    id: '1',
    title: '运营活动',
    contentList: [
      {
        id: '1-1',
        subContentList: [
          { id: '1-1-1', subContent: '拉新引流活动' },
          { id: '1-1-2', subContent: '沉睡用户唤醒活动' },
        ],
      },
      {
        id: '1-2',
        subContentList: [
          { id: '1-2-1', subContent: '优惠券促销活动' },
          { id: '1-2-2', subContent: '会员积分活动' },
        ],
      },
      {
        id: '1-3',
        subContentList: [
          { id: '1-3-1', subContent: '分享裂变活动' },
          { id: '1-3-2', subContent: '折扣满减活动' },
        ],
      },
    ],
  },
  {
    iconId: 'iconmarketing-activity-2',
    id: '2',
    title: '市场活动',
    contentList: [
      {
        id: '2-1',
        subContentList: [
          { id: '2-1-1', subContent: '线下活动/会议/会展' },
          { id: '2-1-2', subContent: '渠道KA营销活动' },
        ],
      },
      {
        id: '2-2',
        subContentList: [
          { id: '2-2-1', subContent: '电商营销活动' },
          { id: '2-2-2', subContent: '用户体验活动' },
        ],
      },
      {
        id: '2-3',
        subContentList: [
          { id: '2-3-1', subContent: '广告投放活动' },
          { id: '2-3-2', subContent: '全员推广活动' },
        ],
      },
    ],
  },
  {
    iconId: 'iconmarketing-activity-3',
    id: '3',
    title: '推送活动',
    contentList: [
      {
        id: '3-1',
        subContentList: [
          { id: '3-1-1', subContent: '内容营销推广' },
          { id: '3-1-2', subContent: '直播营销活动' },
        ],
      },
      {
        id: '3-2',
        subContentList: [
          { id: '3-2-1', subContent: '自媒体营销活动' },
          { id: '3-2-2', subContent: '短信促销活动' },
        ],
      },
      {
        id: '3-3',
        subContentList: [
          { id: '3-3-1', subContent: 'EDM邮件营销活动' },
          { id: '3-3-2', subContent: '纪念日营销活动' },
        ],
      },
    ],
  },
];

const MARKETING_DATA = [
  {
    id: 'marketing-1',
    iconIndex: 44,
    description: '营销自动化平台',
  },
  {
    id: 'marketing-2',
    iconIndex: 45,
    description: '客户数据管理平台',
  },
  {
    id: 'marketing-3',
    iconIndex: 17,
    description: '会员管理平台',
  },
  {
    id: 'marketing-4',
    iconIndex: 46,
    description: '内容管理平台',
  },
  {
    id: 'marketing-5',
    iconIndex: 47,
    description: 'Ad Hub 智能广告平台',
  },
];

export default () => (
  <Layout current="scens" subCurrent="campaign">
    <CommonPageBanner
      title="营销活动管理"
      description="基于MAP营销自动化平台，Convertlab可以帮助您管理和实现覆盖大规模人群和跨渠道的营销活动，在CDP客户数据平台和Ad Hub智能广告平台的支持下，营销活动效果将会更精准，提供更好的用户个性化体验。"
      imgName="management"
    />
    <section id="marketing-management" className="marketing-management">
      <Container>
        <h3>跨渠道营销，支持丰富的活动类型</h3>
        <div className="top-box-wrapper">
          {TOP_BOX_DATA.map((data, index) => {
            return <CircleTopBox key={data.id} index={index} {...data} />;
          })}
        </div>
      </Container>
    </section>
    <TitledVerticalBoxes
      title="Convertlab营销活动中台"
      subTitle="一体化全渠道的营销活动管理中台，覆盖营销活动全链路"
      shadowed
      sectionId="campaign"
      paddingTop={10}
    >
      {MARKETING_DATA.map((item) => (
        <Col className="icon-text-col five-in-row" key={item.id}>
          <VerticalBox {...item} smLayout="in-row" />
        </Col>
      ))}
    </TitledVerticalBoxes>
    <MarketingMgtContentList />
    <HomeLogos />
  </Layout>
);
